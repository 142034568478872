<template>
  <div id="footer" v-show="show">
    <div class="first-line">
      Copyright 2022 深圳市超元创世科技有限公司 · 联系电话 18948745908 · 地址
      广东省深圳市南山区蚂蚁帮创客中心2楼219 ·
      <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2022144574号</a>
    </div>
    <div class="second-line">
      <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502009647" target="_blank">
        <img src="@/assets/images/icons/beian_icon.png" alt=""/>
        <p>粤公网安备 44030502009848号</p>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  created() {
    setTimeout(() => {
      this.show = true;
    },1500)
  }
};
</script>

<style lang="less" scoped>
#footer {
  width: 100%;
  min-width: 1200px;
  background-color: #010301;
  padding: 40px 0;
  box-sizing: border-box;

  .first-line {
    width: 1200px;
    margin: 0 auto;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #f9f9f9;

    a {
      font-size: 12px;
      font-weight: 400;
      color: #f9f9f9;
    }

    a:hover {
      color: #03bd61;
    }
  }

  .second-line {
    width: 1200px;
    margin: 10px auto 0;
    text-align: center;

    a {
      font-size: 12px;
      font-weight: 400;
      color: #f9f9f9;
      display:inline-block;

      img {
        float:left;
        width: 20px;
        height: 20px;
      }

      p {
        float:left;
        margin: 0 0 0 5px;
        font-size: 12px;
        font-weight: 400;
        color: #f9f9f9;
        line-height: 20px;
      }
    }
  }
}
</style>

<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "./components/Footer/Footer.vue";
export default {
  name: "App",
  components: {
    Footer,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  created() {},
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>

<style lang="less">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>

import { Dialog, Pagination, Carousel, CarouselItem, Upload, Select, Option, OptionGroup, Tooltip } from "element-ui";

let components = {
  Dialog,
  Pagination,
  Carousel,
  CarouselItem,
  Upload,
  Select,
  Option,
  OptionGroup,
  Tooltip
};

function install(Vue) {
  Object.keys(components).forEach((key) => Vue.use(components[key]));
}

export default { install };

import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";

import store from "./store/store";
Vue.prototype.$store = store;

import axios from "axios";
Vue.prototype.$http = axios;

// element ui
import elementUI from "./utils/elementui.js";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(elementUI);
import { Message } from "element-ui";
// 全局设置Message显示时间
Vue.prototype.$message = function (msg) {
  return Message({
    message: msg,
    duration: 1500,
  });
};
// 分别对success、warning和error等样式进行设置
Vue.prototype.$message.success = function (msg) {
  return Message.success({
    message: msg,
    duration: 1500,
  });
};
Vue.prototype.$message.warning = function (msg) {
  return Message.warning({
    message: msg,
    duration: 1500,
  });
};
Vue.prototype.$message.error = function (msg) {
  return Message.error({
    message: msg,
    duration: 1500,
  });
};

// vue-lazyload
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: 'https://oss.cycsmeta.com/cloud/230310/17/2023031017392890213/showings_404.png',
  loading: 'https://oss.cycsmeta.com/cloud/230321/11/2023032111120803967/loading.gif',
  attempt: 3
})

let isEmpty = (str) => {
  if (str === "null" || str === "" || str === 0 || str === "0" || str === null || str === false || typeof str === 'undefined' || str.length === 0) {
    return true;
  }
  return false;
};

// 获取预览图片
Vue.prototype.getImageView = (path) => {
  if (isEmpty(path)) {
    return ''
  } else if (path.indexOf('http') > -1) {
    return path;
  } else {
    return `https://oss.cycsmeta.com/${path}`;
  }
};

// 检测用户权限
Vue.prototype.hasPerm = function(key) {
  if (isEmpty(key)) {
    return false;
  } else {
    let arr = store.state.auth || '[]';
    let isOk = false;
    arr.forEach((item) => {
      if (!isOk && key === item) {
        isOk = true;
      }
    })
    return isOk;
  }
}

Vue.config.productionTip = false;

// 动态修改页面title
router.beforeEach((to, from, next) => {
  if (to.query.name) {
    document.title = to.query.name;
  }
  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

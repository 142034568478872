import Vue from "vue";
import VueRouter from "vue-router";

// 冗余导航
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

// 路由表
const routes = [
  {
    path: "/",
    redirect: "/home/index",
    component: () => import("@/views/home/index"),
    meta: {
      title: "SHOWINGS首映式",
    },
  },
  {
    path: "/home/index",
    name: "Index",
    component: () => import("@/views/home/index"),
    meta: {
      title: "SHOWINGS首映式",
    },
  },
  {
    path: "/home/search",
    name: "Search",
    component: () => import("@/views/home/search"),
    meta: {
      title: "SHOWINGS首映式",
    },
  },
  {
    path: "/home/details",
    name: "Details",
    component: () => import("@/views/home/details"),
  },
  {
    path: "/user/personal",
    name: "Personal",
    component: () => import("@/views/user/personal"),
  },{
    path: "/user/upload",
    name: "Upload",
    component: () => import("@/views/user/upload"),
  },
];

const router = new VueRouter({
  mode: "hash",
  // mode: "history",
  routes,
});

export default router;

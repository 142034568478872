import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import { localUtils } from "@/utils/local.js";

export default new Vuex.Store({
  namespaced: true,
  state: {
    userInfo: localUtils.get("userInfo") || "",
    userId: localUtils.get("userId") || null,
    token: localUtils.get("token") || "",
    auth: localUtils.get("auth") || [],
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
      localUtils.set("token", state.token);
    },
    SET_USER: (state, userInfo) => {
      state.userInfo = userInfo;
      localUtils.set("userInfo", state.userInfo);
    },
    SET_USERID: (state, id) => {
      state.userId = id;
      localUtils.set("userId", state.userId);
    },
    SET_AUTH: (state, auth) => {
      state.auth = auth;
      localUtils.set("auth", state.auth);
    },
  },
  actions: {},
  modules: {},
});
